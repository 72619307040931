import './NavigationBar.scss'
import { useState, useEffect} from 'react'
import {Link} from 'react-router-dom'

// import { servicesRef } from '../ServicesSection/ServicesSection'
// import { aboutRef } from '../AboutSection/AboutSection'

import { RxHamburgerMenu as OpenMenu } from "react-icons/rx"
import { CgClose as HideMenu} from 'react-icons/cg' 
// import { MdNoEncryption } from 'react-icons/md'

const NavigationBar = () => {
  
  // const handleClick = (elmRef) =>{
  //   window.scrollTo({ top: elmRef.current.offsetTop,behavior: 'smooth' })
  // }

  const [menuOpen, setMenuOpen] = useState(false)
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
      window.innerWidth > 1200 && setMenuOpen(false)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      <nav className="navBar">
        <div className="leftSection">

            <div className="navBtns">
              <div className='navDropdown'>
                <button><Link to={`/`} className='link'>Home</Link></button>
              </div>
              {/* <div className="navDropdown">
                <button onClick={()=>handleClick(aboutRef)}>About Us</button>
              </div> */}
              {/* <div className="navDropdown">
                <button onClick={()=>handleClick(servicesRef)}>Services</button>
              </div> */}
              <div className='navDropdown'>
                <button><Link to={`/privacy-policy`} className='link'>Privacy Policy</Link></button>
              </div>
              <div className='navDropdown'>
                <button><Link to={`/contact-us`} className='link'>Support</Link></button>
              </div>
              
            </div>
        </div>

        <div className='rightSection'>
          <div className="menuSection">
            {
              !menuOpen ? 
                <OpenMenu className='menuBtn' onClick={() => setMenuOpen(true)}/> : 
                <HideMenu className='hideBtn' onClick={() => setMenuOpen(false)}/>
            }
          </div>
        </div>
        
        <div className="sideMenu" style={{display: (!menuOpen || width > 1200) ? 'none' : 'flex'}}>
          <div className="sideMenuSiteSections">
            <button onClick={() => setMenuOpen(false)}><Link to={`/`} className='link'>Home</Link></button>
            <button onClick={() => setMenuOpen(false)}><Link to={`/`} className='link'>About Us</Link></button>
            <button onClick={() => setMenuOpen(false)}><Link to={`/`} className='link'>Services</Link></button>
            <button onClick={() => setMenuOpen(false)}><Link to={`/privacy-policy`} className='link'>Privacy Policy</Link></button>
            <button onClick={() => setMenuOpen(false)}><Link to={`/contact-us`} className='link'>Support</Link></button>
          </div>
          {/* <div className="sideMenuContent">
            <div className="sideMenuAccountHeader">
              <h1>Login or Sign Up</h1>
            </div>
            <div className="loginSignUpSection">
              <div className="sideMenuAccountBtns">
                <button>Login</button>
                <button>Sign Up</button>
              </div>
              <div className='disclaimer'>
                &copy; Company. All rights reserved. All trademarks are property of their respective owners in the US and other countries.
              </div>
            </div>
          </div> */}
        </div>
      </nav>
    </>
  );
}

export default NavigationBar
